import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { IUser } from 'app/shared/models/user';

const user = createActionGroup({
  source: 'User',
  events: {
    initData: emptyProps(),
    editUser: props<{ user: Partial<IUser> }>(),
    updateUser: props<{ user: Partial<IUser> }>(),
    resetState: emptyProps(),
    checkTwoFactorAuth: emptyProps(),
    twoFactorAuthSendPhoneNumber: props<{ phoneNumber: string }>(),
    twoFactorAuthSendCode: props<{ code: string }>(),
    twoFactorAuthGenerateCode: emptyProps()
  }
});

const userApi = createActionGroup({
  source: 'User/API',
  events: {
    loadSuccess: props<{ user: Partial<IUser> }>(),
    loadFail: props<{ error: string }>(),
    editSuccess: props<{ user: Partial<IUser> }>(),
    twoFactorAuthSendPhoneNumberSuccess: emptyProps(),
    twoFactorAuthSendPhoneNumberFail: emptyProps(),
    twoFactorAuthGenerateCodeSuccess: emptyProps(),
    twoFactorAuthSendCodeSuccess: emptyProps(),
    twoFactorAuthSendCodeFail: emptyProps()
  }
});

export const UserActions = {
  ...user,
  ...userApi
};
