import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { IUser } from 'app/shared/models/user';
import { UserActions } from './user.actions';

export interface UserState {
  user: Partial<IUser>;
  updatePhoneSuccess: boolean;
  validateCodeSuccess: boolean;
}

export const initialState: UserState = {
  user: null,
  updatePhoneSuccess: null,
  validateCodeSuccess: null
};

export const userFeature = createFeature({
  name: 'userState',
  reducer: createReducer(
    initialState,
    on(UserActions.loadSuccess, (state, { user }) => ({ ...state, user })),
    on(UserActions.editSuccess, UserActions.updateUser, (state, { user }) => ({ ...state, user: { ...state.user, ...user } })),
    on(UserActions.resetState, () => initialState),
    on(UserActions.twoFactorAuthSendPhoneNumberSuccess, state => ({ ...state, updatePhoneSuccess: true })),
    on(UserActions.twoFactorAuthSendPhoneNumberFail, state => ({ ...state, updatePhoneSuccess: false })),
    on(UserActions.twoFactorAuthSendCodeSuccess, state => ({ ...state, validateCodeSuccess: true })),
    on(UserActions.twoFactorAuthSendCodeFail, state => ({ ...state, validateCodeSuccess: false }))
  ),
  extraSelectors: ({ selectUser }) => ({
    selectUserId: createSelector(selectUser, user => user.idUsuario)
  })
});

const { name, reducer, ...selectors } = userFeature;
export const fromUser = selectors;
